<template>
  <nav
    v-if="hasNavbar"
    class="bg-white dark:bg-notion-dark"
  >
    <div class="max-w-7xl mx-auto px-8">
      <div class="flex items-center justify-between h-16">
        <div class="flex items-center">
          <NuxtLink
            :to="{ name: user ? 'home' : 'index' }"
            class="flex-shrink-0 font-semibold flex items-center"
          >
            <img
              src="/img/logo.svg"
              alt="airtable tools logo"
              class="w-8 h-8"
            >
            <span
              class="ml-3 text-xl hidden sm:inline text-transparent dark:text-white bg-clip-text bg-gradient-to-r from-blue-400 to-blue-600"
            >
              AirFormTable
            </span>
          </NuxtLink>
          <workspace-dropdown class="ml-6" />
        </div>
        <div
          v-if="showAuth"
          class="block"
        >
          <div class="ml-4 flex items-center md:ml-6">
            <div
              v-if="(user===null || (user && !user.has_enterprise_subscription)) && $route.name !== 'pricing'"
              class="hidden sm:block"
            >
              <NuxtLink
                v-if="
                  !user || (user && !user.is_subscribed)
                "
                v-track.nav_pricing_click
                :to="{ name: 'pricing' }"
                class="text-gray-400 dark:text-white hover:text-gray-800 dark:hover:text-white px-0 sm:px-3 py-2 rounded-md text-sm font-medium router-link-exact-active"
              >
                <span
                  v-if="
                    user &&
                      user.is_subscribed &&
                      !user.has_enterprise_subscription
                  "
                >Upgrade</span>
                <span v-else>Pricing</span>
              </NuxtLink>
            </div>
            <!--            <div-->
            <!--              v-if="user"-->
            <!--              class="hidden md:block mr-10 relative"-->
            <!--            >-->
            <!--              <a-->
            <!--                :href="helpUrl"-->
            <!--                class="text-sm text-gray-400 dark:text-white hover:text-gray-800 cursor-pointer mt-1"-->
            <!--                target="_blank"-->
            <!--              >-->
            <!--                Help-->
            <!--              </a>-->
            <!--            </div>-->
            <div class="ml-3 mr-4 relative">
              <div class="relative inline-block text-left">
                <dropdown
                  v-if="user"
                  dusk="nav-dropdown"
                >
                  <template #trigger="{ toggle }">
                    <button
                      id="dropdown-menu-button"
                      type="button"
                      class="flex items-center justify-center w-full rounded-md px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-50 hover:bg-gray-50 dark:hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-gray-500"
                      dusk="nav-dropdown-button"
                      @click.stop="toggle()"
                    >
                      <UAvatar
                        :src="user.photo_url"
                        :alt="user.name"
                        size="xs"
                      />
                      <p class="ml-2 hidden sm:inline">
                        {{ user.name }}
                      </p>
                    </button>
                  </template>

                  <NuxtLink
                    v-if="userOnboarded"
                    :to="{ name: 'home' }"
                    class="flex md:hidden px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600 flex items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-4 h-4 mr-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                      />
                    </svg>
                    My Forms
                  </NuxtLink>

                  <NuxtLink
                    v-if="user && user.has_customer_id"
                    :to="{ name: 'settings-billing' }"
                    class="block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600 flex items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-4 h-4 mr-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                      />
                    </svg>
                    Billing
                  </NuxtLink>

                  <NuxtLink
                    :to="{ name: 'settings-profile' }"
                    class="block block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600 flex items-center"
                  >
                    <svg
                      class="w-4 h-4 mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                      />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                    Settings
                  </NuxtLink>

                  <NuxtLink
                    v-if="user.moderator"
                    :to="{ name: 'settings-admin' }"
                    class="block block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600 flex items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4 mr-2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 12.75 11.25 15 15 9.75m-3-7.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285Z"
                      />
                    </svg>
                    Admin
                  </NuxtLink>

                  <a
                    href="#"
                    class="block block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600 flex items-center"
                    @click.prevent="logout"
                  >
                    <svg
                      class="w-4 h-4 mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                      />
                    </svg>
                    Logout
                  </a>
                </dropdown>
                <div v-else>
                  <NuxtLink
                    v-if="$route.name !== 'login'"
                    :to="{ name: 'login' }"
                    class="text-gray-400 dark:text-white hover:text-gray-800 dark:hover:text-white px-0 sm:px-3 py-2 rounded-md text-sm font-medium"
                    active-class="text-gray-800 dark:text-white"
                  >
                    Login
                  </NuxtLink>
                  <NuxtLink
                    :to="{ name: 'register' }"
                    class="text-gray-300 hover:text-gray-800 dark:hover:text-white pl-3 py-2 rounded-md text-sm font-medium"
                    active-class="text-gray-800 dark:text-white"
                  >
                    <v-button
                      v-track.nav_create_form_click
                      class="inline-block group"
                    >
                      Create form
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-4 h-4 inline -mt-[2px] transition-transform group-hover:translate-x-1"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                        />
                      </svg>
                    </v-button>
                  </NuxtLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import {computed} from "vue"
import Dropdown from "~/components/global/Dropdown.vue"
import WorkspaceDropdown from "./WorkspaceDropdown.vue"
import airtableformsConfig from '~/airtableforms.config.js'

export default {
  components: {
    WorkspaceDropdown,
    Dropdown,
  },

  async setup() {
    const authStore = useAuthStore()
    const formsStore = useFormsStore()
    const workspacesStore = useWorkspacesStore()
    return {
      authStore,
      airtableformsConfig,
      appStore: useAppStore(),
      formsStore,
      workspacesStore,
      config: useRuntimeConfig(),
      user: computed(() => authStore.user),
      isIframe: useIsIframe(),
    }
  },

  computed: {
    helpUrl() {
      return this.airtableformsConfig.links.help_url
    },
    form() {
      if (this.$route.name && this.$route.name.startsWith("forms-slug")) {
        return this.formsStore.getByKey(this.$route.params.slug)
      }
      return null
    },
    showAuth() {
      return this.$route.name && this.$route.name !== 'forms-slug'
    },
    hasNavbar() {
      if (this.isIframe) return false

      if (this.$route.name && this.$route.name === "forms-slug") {
        if (this.form) {
          // If there is a cover, or if branding is hidden remove nav
          if (this.form.cover_picture || this.form.no_branding) {
            return false
          }
        } else {
          return false
        }
      }
      return !this.appStore.navbarHidden
    },
    userOnboarded() {
      return this.user && this.user.workspaces_count > 0
    },
    hasCrisp() {
      return (
        this.config.public.crispWebsiteId &&
        this.config.public.crispWebsiteId !== ""
      )
    },
  },

  mounted() {
  },

  methods: {
    async logout() {
      // Log out the user.
      this.authStore.logout()

      // Reset store
      this.workspacesStore.resetState()
      this.formsStore.resetState()

      // Redirect to login.
      const router = useRouter()
      router.push({name: 'login'})
    }
  },
}
</script>
